<template>
  <div id="app">
    <div class="bg_green" style="padding-bottom:3vw">
      <div class="white_box3" style="box-shadow:none">
        <div class="white_box3_in" style="padding-bottom:0;">
          <!-- 用户 -->
          <div class="user pd_3p6_0 clear" v-if="customerUser.businessCustomerUser">
            <div class="user_avatar larger" :style="{backgroundImage:`url(${img2})`}"></div>
            <div class="user_msg">
              <span>{{ customerUser.businessCustomerUser.name }}</span>
              <label>{{
                  customerUser.businessCustomerUser.business.name
                }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="white_box4 mgt_3vw" v-if="paymentInfo && paymentInfo.needPassword === '1'">
      <div class="balance_list">
        <div class="box_line"></div>
        <ul class="clear">
          <li>
            <div class="balance_list_t">自由消费余额</div>
            <div class="balance_list_b">{{ totalPrice.toFixed(2) }}</div>
          </li>
          <li>
            <div class="balance_list_t orange">套餐余额</div>
            <div class="balance_list_b">{{ totalBundleBalance.toFixed(2) }}</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="white_box4 mgt_3vw">
      <van-cell title="交易记录" is-link
                @click="$router.push({path: '/transaction_log',query:{businessCustomerUserId:businessCustomerUserId}})"/>
    </div>

    <div class="white_box4 mgt_3vw">
      <van-cell title="套餐明细" style="font-weight: bold;"/>
      <van-cell v-for="(option,index) in bundleCustomerUserAccountOptions" :key="index"
                :title="option.productBundle?option.productBundle.name:'-'" :value="option.balance.toFixed(2)"/>
      <van-empty v-if="!bundleCustomerUserAccountOptions || bundleCustomerUserAccountOptions.length===0"
                 description="暂无数据"/>
    </div>

    <div class="loading_all" v-if="loading">     
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<style scoped>
#app {
  background: #fff;
  height: 100vh;
  overflow: scroll;
}
</style>

<script>
import Vue from 'vue'
import {post} from '@/utils/http'

export default {
  data() {
    return {
      img: require('../assets/images/avatar.png'),
      img2: require('../assets/images/avatar2.png'),
      businessCustomerUserId: null,
      loading: false,
      customerUser: {},

      totalPrice: 0,
      totalBundleBalance: 0,
      bundleCustomerUserAccountOptions: [], // PRODUCT_BUNDLE

      paymentInfo: {},
    };
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.businessCustomerUserId = this.$route.query.businessCustomerUserId;
    this.loading = true
    post('api/customer/user/v2/search', {
      filters: [{name: 'businessCustomerUserId', op: '=', value: this.businessCustomerUserId}]
    }).then(res => {
      this.customerUser = res.data.rows[0]
      this.loadCustomerUserAccount();
      this.loadBusinessPaymentInfo();
    }).catch(() => this.loading = false)
  },
  methods: {
    goBack() {
      this.$router.push('/customer_user_account');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    loadCustomerUserAccount() {
      this.loading = true
      post('api/user/pocket/v2/search', {
        customerUserId: this.customerUser.id,
        businessCustomerUserId: this.businessCustomerUserId
      }).then(res => {
        if (res.data) {
          res.data.forEach(item => {
            if (item.type === 'PRODUCT_BUNDLE') {
              this.bundleCustomerUserAccountOptions.push(item)
              this.totalBundleBalance += item.balance;
            }
            if (item.type === 'FREEDOM') {
              if (item.balance) {
                this.totalPrice += item.balance;
              }
            }
          })
        }
        this.loading = false
      }).catch(() => this.loading = false)
    },
    loadBusinessPaymentInfo() {
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
          })
          .catch(error => {
            console.log(error.message);
          })
    }
  }
};
</script>
